/*
html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
}
*/
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./satellite.css";
.ais-SearchBox-input {
  padding-left: 40px;
}

body {
  @apply bg-white text-black
}
.alert {
  @apply p-2 inline-block
}
.alert-yellow {
  @apply bg-yellow-400
}

.btn {
  @apply py-1 px-2 rounded inline-block cursor-default
}
.btn:disabled {
  @apply opacity-50
}

.btn-outline-black {
  @apply bg-white outline outline-black text-black
}

.btn-black {
  @apply bg-black text-white
}

.btn-gray {
  @apply bg-gray-200 text-black
}
.btn-green {
  @apply bg-green-400 text-white
}
.btn-red {
  @apply bg-red-400 text-white
}
.btn-yellow {
  @apply bg-yellow-400 text-black
}
.btn-blue {
  @apply bg-blue-400 text-white
}
.btn-link {
  @apply underline  cursor-pointer
}
.link {
  @apply underline cursor-pointer
}


.table-header-group {
  @apply bg-gray-200
}
.table-cell {
  @apply p-2
}

.oms-form > div {
  @apply mb-2
}
.oms-form input[type="text"], .oms-form textarea {
  @apply w-full
}
.oms-form input:disabled {
  @apply bg-gray-100 text-gray-600
}